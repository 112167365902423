






































































import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import InteractionLib from "@/utils/interaction.lib";
import { countdown, getNow } from "@/utils/common.util";
import { MallHomeSpecialSale } from "@/api/cap-store.api";

@Component
export default class ProductCards extends Vue {
  @Prop() private contentWidth!: any;
  @Prop() private product!: MallHomeSpecialSale;
  @Prop() isVipMember!:boolean;
  time_text: any = "";
  timer: any;

  get isPrice() {
    return this.product.pay_type == 1;
  }

  get gold_unique() {
    return this.product.gold_unique;
  }

  get is_gold_price() {
    return this.product.is_gold_price;
  }

  get price() {
    const { price,gold_unique, gold_price,sale_price, is_sale } = this.product;
    if(this.isVipMember){
      return gold_price? gold_price: is_sale? sale_price:price;
    }
    return gold_unique?gold_price : is_sale? sale_price:price;
  }

  get bgColorChange() {
    const height =
      typeof this.contentWidth === "number"
        ? this.contentWidth
        : this.contentWidth.height;
    if (this.product.home_sort < 0) {
      return {
        background: "linear-gradient(90deg, #ffebb3 0%, #ffcb66 100%)",
        color: "#933b00",
        top: `calc(${height} - 20px)`,
      };
    }
    return {
      background: "#FF3648",
      opcity: "0.95",
      color: "white",
      top: `calc(${height} - 20px)`,
    };
  }

  created() {
    if (this.product.start_time > getNow()) {
      this.timer = setInterval(() => {
        const { day, hour, minute, second } = countdown(
          this.product.start_time
        );
        this.time_text = `距开售还有 ${day}天${hour}:${minute}:${second}`;
      }, 1000);
    }
  }

  handleOpenDetail(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/sp-store/${this.product.id}`
      );
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${this.product.id}` },
      query: { time: String(getNow()), id: `${this.product.id}` },
    });
  }
}
