







































































































































































































import { Component, Vue } from "vue-property-decorator";
import UserMedia from "@/views/mine/components/UserMedia.vue";
import { CellGroup, Cell } from "vant";
import { WechatApi } from "@/api/wechat.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import InteractionLib from "@/utils/interaction.lib";
import MemberApi from "@/api/member.api";
@Component({
  components: { UserMedia, CellGroup, Cell },
})
export default class Mine extends Vue {
  wechat = InteractionLib.mobile.wechat;
  list = [
    {
      img: "https://cdn.vgn.cn/static/h5fast/1.png",
      title: "全部",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/2.png",
      title: "未拆封",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/3.png",
      title: "已拆封",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/4.png",
      title: "待发货",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/5.png",
      title: "已发货",
    },
  ];
  mall_buy = [
    {
      img: "https://cdn.vgn.cn/static/h5fast/6.png",
      title: "我的订单",
    },
    {
      img: "https://cdn.vgn.cn/static/Official-website/mysell2.png",
      title: "我的挂售",
    },
    {
      img: "https://cdn.vgn.cn/static/Official-website/demand.png",
      title: "我的求购",
    },
    {
      img: "https://cdn.vgn.cn/static/Official-website/bagg.png",
      title: "钱包",
    },
  ];

  globalState = getModule(GlobalStateStore);

  toorder() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-order`);
    }
    this.$router.push({ path: "/my-order" });
  }

  tocuppon() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-discount`);
    }
    this.$router.push({ path: "/my-discount" });
  }
  todemand() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/demand`);
    }
    this.$router.push({ path: "/demand" });
  }
  towallet() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/wallet`);
    }
    this.$router.push({ path: "/wallet" });
  }
  tomysell() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sell`);
    }
    this.$router.push({ path: "/sell" });
  }
  toMemberPage() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-page`);
    }
    this.$router.push({
      name: "memberpage",
    });
  }
  handleFeedBack(){
    location.href = "https://support.qq.com/products/373434"
  }
  async handleLoading() {
    if (!this.wechat) {
      InteractionLib.login();
      return;
    }
    location.href = await WechatApi.getAuthUri();
  }
  toLookCurrent() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-page`);
    }
    this.$router.push({
      name: "memberpage",
    });
  }
  toLookNext() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-page`);
    }
    this.$router.push({
      name: "memberpage",
    });
  }
  toSupply(num: any) {
    this.$router.push({
      name: "mysupply",
      query: { num },
    });
  }
  RecentlyGame: any = null;
  isVip: number = 0;
  userInfoImg: any = null;
  MineData: any = null;
  mounted() {}
  async created() {
    try {
      this.RecentlyGame = await MemberApi.getRecentlyGame();
      this.MineData = await MemberApi.getMineData();
      const userInfo: any = await this.globalState.getUserInfo().catch(() => {
        return false;
      });
      if (userInfo) {
        this.userInfoImg = userInfo.medal;
      } else {
        this.userInfoImg = null;
      }
      const { status } = await MemberApi.checkMemberIs();

      this.isVip = status;
    } catch (err) {
      console.log(err);
    }
  }
  handleOpenImage() {
    location.href = "https://work.weixin.qq.com/kfid/kfc3cc5741444048d77";
  }
}
