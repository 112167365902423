


























































import { Component, Prop, Vue } from "vue-property-decorator";
import { StoreProduct } from "@/api/cap-store.api";
import {
  countdown,
  getNow,
  momentFormatNot,
  observer,
  unObserver,
} from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({})
export default class SalesMiniItem extends Vue {
  @Prop() readonly item!: StoreProduct;
  countdown_text = "";
  start_text = "";
  is_start = false;

  get inventory(): number {
    return this.item.inventory;
  }

  get snapUp(): boolean {
    const { repertory } = this.item;
    return Boolean(repertory);
  }

  get progress_text(): number {
    const { repertory, inventory } = this.item;
    if (repertory === 0) return 0;
    return Math.floor(((inventory - repertory) / inventory) * 100);
  }

  handleCountdownNotice(): void {
    const { finish_time, start_time } = this.item;
    const { day, hour, minute, second } = countdown(finish_time || 0);
    if(Number(day)>13){
      this.countdown_text = ''
    }else{
      this.countdown_text = `
        <div class="__sales_countdown hidden-text-1">距结束还有 ${day}天</div>
        <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
      `;
    }
    if (start_time > Math.ceil(Date.now() / 1000)) {
      this.is_start = true;
      this.start_text = momentFormatNot(start_time, true);
    } else if (this.is_start && this.is_start) {
      this.is_start = false;
    }
  }

  mounted(): void {
    observer("countdownNotice", this.handleCountdownNotice.bind(this));
  }

  destroyed(): void {
    unObserver("countdownNotice", this.handleCountdownNotice.bind(this));
  }

  handleOpenDetail(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${this.item.id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${this.item.id}` },
      query: { time: String(getNow()) },
    });
  }
}
