<template>
  <div class="pop">
    <div
      @click.stop="check(1)"
      :class="{
        active: activeState == 1 ? true : false,
        item: activeState == 1 ? false : true,
      }"
    >
      不限
    </div>
    <div
      @click.stop="check(2)"
      :class="{
        active: activeState == 2 ? true : false,
        item: activeState == 2 ? false : true,
      }"
    >
      40元以内
    </div>
    <div
      @click.stop="check(3)"
      :class="{
        active: activeState == 3 ? true : false,
        item: activeState == 3 ? false : true,
      }"
    >
      100元以内
    </div>
    <div
      @click.stop="check(4)"
      :class="{
        active: activeState == 4 ? true : false,
        item: activeState == 4 ? false : true,
      }"
    >
      300元以内
    </div>
    <div class="triangle"></div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class popitem extends Vue {
  activeState = 1;
  check(e) {
    this.activeState = e;
    this.$emit("closePop", e);
  }
}
</script>
<style lang="scss" scoped>
.pop {
  width: 120px;
  height: 160px;
  background: white;
  color: black;
  border-radius: 8px;
  padding: 15px 20px;
  box-sizing: border-box;
  .active {
    color: black;
    font-weight: 800;
    font-size: 13px;
    height: 35px;
    display: flex;
    align-items: center;
  }
  .item {
    height: 35px;
    display: flex;
    align-items: center;
    color: gray;
    font-size: 13px;
  }
  .triangle {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -7px;
    transform: rotate(-45deg);
    background: white;
    left: 80px;
  }
}
</style>
