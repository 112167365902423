// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SecureLS from "secure-ls";

export const storageKey = "vuex-item"; // the key used by localStorage

// export const secureStorage = new SecureLS({ isCompression: true });
const initialStoreContent = localStorage.getItem(storageKey); // or localStorage.get(storageKey)
export const initialUnencryptedStorage = initialStoreContent
  ? JSON.parse(initialStoreContent)
  : {}; // parse the localStorage value to an object when it's defined, else set it as an empty object
