<template>
  <div class="bgwrap" :style="{ paddingTop: `${statusBar == 0?`0`:`${statusBar}`}px` }">
    <div class="content">
      <div style="background: linear-gradient(180deg, #0C121A 0%, #131B29 100%);height:5px"></div>
      <div class="flex-row">
        <div>更多功能</div>
        <div>
          <img
            @click="closepop"
            src="https://cdn.vgn.cn/static/Official-website/alotclose.png"
            alt=""
          />
        </div>
      </div>
      <div class="__flex_wrap">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item"
          @click="toWhere(index)"
        >
          <img :src="item.img" alt="" style="margin-top: 10px" />
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div @click="closepop" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import InteractionLib from "@/utils/interaction.lib";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AlotofItem extends Vue {
  globalState = getModule(GlobalStateStore);
  list = [
    {
      img: "https://cdn.vgn.cn/static/h5fast/tosupply.png",
      title: "我的补给",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/toorder.png",
      title: "我的订单",
    },
    {
      img: "https://cdn.vgn.cn/static/new/imgbag.png",
      title: "我的钱包",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/tocoupon.png",
      title: "优惠卷",
    },
    {
      img: "https://cdn.vgn.cn/static/h5fast/toup.png",
      title: "合成升级",
    },
    {
      img: "https://cdn.vgn.cn/static/market/%E5%9B%BE%E6%A0%87-%E5%90%88%E6%88%90%E5%8D%87%E7%BA%A7%403x.png",
      title: "合成自选",
    },
    {
      img: "https://cdn.vgn.cn/static/new/imgsell.png",
      title: "我的挂售",
    },
    {
      img: "https://cdn.vgn.cn/static/new/imgdemand.png",
      title: "我的求购",
    },
  ];
  get statusBar() {
    return this.globalState.statusBarHeight;
  }
  toWhere(num) {
    if (num == 0) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/mysupply`);
      }
      this.$router.push({ path: "/mysupply" });
    } else if (num == 1) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/my-order`);
      }
      this.$router.push({ path: "/my-order" });
    } else if (num == 2) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/wallet`);
      }
      this.$router.push({ path: "/wallet" });
    } else if (num == 3) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/my-discount`);
      }
      this.$router.push({ path: "/my-discount" });
    } else if (num == 4) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/synthetic`);
      }
      this.$router.push({ path: "/synthetic" });
    } else if (num == 5) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/optional`);
      }
      this.$router.push({ path: "/optional" });
    } else if (num == 6) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/sell`);
      }
      this.$router.push({ path: "/sell" });
    } else if (num == 7) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/demand`);
      }
      this.$router.push({ path: "/demand" });
    }
    document.documentElement.style.overflowY = "scroll";
  }
  closepop() {
    this.$emit("closepop");
    document.documentElement.style.overflowY = "scroll";
  }
}
</script>
<style lang="scss" scoped>
.bgwrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .content {
    width: 100%;
    background: #f5f5f5;
    position: relative;
    top: 0;
    left: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 12px;
      font-weight: 800;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .__flex_wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 12px;
      justify-content: space-between;
      .item {
        width: 23%;
        height: 80px;
        border-radius: 6px;
        background: white;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 32px;
          height: 32px;
        }
        .title {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
