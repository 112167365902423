import axios from "axios";
import { Toast } from "vant";

export default class SupplyPackageApi {
  static async recordVisitLog(id: number): Promise<void> {
    return axios.get(`/apiv2/v2/blindbox/record/visit/${id}`);
  }

  static async getEntitySupplyDetail(id: number): Promise<IEntitySupply> {
    return await axios.get(`/apiv2/v2/blindbox/entity/detail/${id}`);
  }
  static async getSupplyList(
    params: GetSupplyListDto
  ): Promise<Array<ISupply>> {
    return await axios.get(`/apiv2/blindbox/list`, {
      params,
    });
  }

  static async getSupplyDetail(box_id: number): Promise<ISupplyDetail> {
    return await axios.get(`/apiv2/blindbox/detail/${box_id}`);
  }

  static async getSupplyBoxList(box_id: number): Promise<Array<ISupplyBox>> {
    return await axios.get(`/apiv2/blindbox/supply/list`, {
      params: { box_id },
    });
  }
  static async getWePlayBoxList(): Promise<Array<ISupplyBox>> {
    return await axios.get(`/mall-activity/we-play/supply-list`);
  }
  static async PostWePlay(phone: string, code_str: string, code_value: string) {
    return await axios.post(`/mall-activity/we-play`, { phone, code_str, code_value })
      .catch((err) => {
        Toast(err.message)
        throw err;
      });
  }
  static async getWePlayRecord(phone: string, code_str: string, code_value: string): Promise<IGetWePlayRecord> {
    return await axios.get(`/mall-activity/we-play`, {
      params: {
        phone, code_str, code_value
      }
    })
    .then((e: any) => e)
      .catch((err) => {
        Toast(err.message)
        throw err;
      });
  }
  static async getSupplyShufflingMessage(
    box_id?: number,
    page_size = 100
  ): Promise<Array<ISupplyShufflingMessage>> {
    return await axios.get(`/apiv2/blindbox/message/list`, {
      params: { box_id, page_size },
    });
  }

  static async createOrderV2(
    box_id: number,
    pay_channel: number,
    num = 1,
    openid = "",
    deduction_wallet?: boolean
  ): Promise<IOrderV2> {
    return await axios.post(`/apiv2/v2/blindbox/order`, {
      box_id,
      pay_channel,
      num,
      openid,
      deduction_wallet,
    });
  }

  static async createOrder(box_id: number): Promise<IOrder> {
    return await axios.post(`/apiv2/blindbox/order`, { box_id });
  }

  static async orderStatus(order_no: string): Promise<IOrderStatus> {
    return await axios.get(`/apiv2/blindbox/order`, {
      params: { order_no },
    });
  }

  static async orderStatusV2(order_no: string): Promise<IOrderStatusV2> {
    return await axios.get(`/apiv2/v2/blindbox/order`, {
      params: { order_no },
    });
  }
  static async upgradePreview(character: number) {
    return await axios.get(`/mall-voucher/upgrade/preview`, {
      params: { character },
    }).catch(() => undefined);
  }
}

export interface ListDtoBase {
  page?: number;
  page_size?: number;
}

export type GetSupplyListDto = ListDtoBase & { price?: string };

export interface IEntitySupply {
  cover: string;
  character: number;
  id: string;
  detail: string;
  record: string;
  status_text: string;
  title: string;
  status: number;
  support_recycle:boolean;
  create_time: number;
}

export interface ISupply {
  id: number;
  title: string;
  cover: string;
  end_time: number;
  price: number;
  supply_num: number;
  box_status: 0 | 1 | 2;
  pay_type: boolean;
  point: number;
}

export interface ISupplyDetail {
  id: number;
  show_chance: boolean;
  title: string;
  cover: string;
  end_time: number;
  price: number;
  totle_price: number;
  totle_point: number;
  content: string;
  supply_num: number;
  five_status: boolean;
  box_status: 0 | 1 | 2;
  pay_type: boolean;
  point: number;
  recycle_status: boolean;
  totle_text?: string;
  is_today_free: boolean;
  has_free_receive: boolean;
  start_time: number;
  ensure_character: number;
  ensure_num: number;
  current_count: number;
}

export interface ISupplyCardItem {
  cover: string;
  character: number;
  rate: string;
  price: number;
  id: number;
  product_bazaar_price: number;
  title: string;
}

export interface ISupplyBox {
  original_chance:number;
  id: number;
  title: string;
  cover: string;
  product_id: number;
  product_bazaar_price: number;
  character: number;
  product_price: number,
  price: number;
  chance: number;
  level: number;
  pay_type: boolean;
  point: number;
  type: number;
  recycle_status?:boolean;
  duration?: string;
  supply_type?: number;
  detail: string;
  coupon?: {
    type: number;
    cap_store?: capStore;
    blind_box?: blindBox;
  };
  ensure?: {
    character?: number;
    num?: number;
    count?: number;
  }
}

export interface blindBox {
  price: number;
  avg_price: number;
  id: number;
  title: string;
}

export interface capStore {
  bazaar_text: string;
  voucher_group: number;
  price: number;
  bazaar_price: number;
  avg_price: number;
  id: number;
  title: string;
}

export interface qualityItem {
  color: string;
  background: string;
  text: string;
}

export interface ISupplyShufflingMessage {
  cover: string;
  member_nickname: string;
  character: number;
  user_id: number;
  level: number;
  price: number;
  name: string;
  member_img: string;
  id: number;
  title: string;
  quality: qualityItem;
}

export interface IOrderV2 {
  pay_channel: number;
  alipay?: any;
  weixin?: any;
  order_no: string;
  wechatPay?: any;
}

export interface IOrder {
  alipay: string;
  order_no: string;
}

export interface IOrderStatusDetail {
  ensure_num: number;
  ensure_character: number;
  current_count: number;
}

export interface IOrderStatusV2 extends IOrderStatus<number[]> {
  user_id: number;
  box_id: number;
  pay_channel: number;
  supply_id: number[];
  detail: IOrderStatusDetail;
}

export interface IOrderStatus<T = number> {
  id: number;
  supply_id: T;
  order_status: number;
}

export interface IPayResult {
  memo: string;
  resultStatus: string;
  result: {
    alipay_trade_app_pay_response: {
      code: string;
      msg: string;
      total_amount: string;
      app_id: string;
      trade_no: string;
      seller_id: string;
      out_trade_no: string;
    };
  };
}

export interface IGetWePlayRecord {
  message: string;
  status: 0 | 1 | 2
  supply_id: number
  voucher_code: string
}
