







































































































































import { MallHomeSpecialSale } from "@/api/cap-store.api";
import { countdown, getNow, monthday } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { min } from "moment";
import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class TimeLimit extends Vue {
  @Prop() items!: MallHomeSpecialSale[];
  @Prop() isVipMember!:boolean;
  timer = [];

  handleJumpList(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/special/sales`);
    }
    this.$router.push({
      name: "SpecialSales",
      query: { time: String(getNow()) },
    });
  }
  handleOpenDetail(id: any): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()), id: `${id}` },
    });
  }
  created() {
    this.items.map((item, index) => {
      if (item.start_time > getNow()) {
        this.items[index].readyFlag = true;
        setInterval(() => {
          const { day, hour, minute, second } = countdown(item.start_time);
          console.log(day, hour, minute, second);
          if (Number(hour) == 0 && Number(minute) == 0 && Number(second) == 0)
            this.items[index].readyFlag = false;
          this.items[
            index
          ].timeDesc = `距开售还有 ${day}天${hour}:${minute}:${second}`;
          this.$forceUpdate();
        }, 1000);
      } else {
        this.items[index].readyFlag = false;
        setInterval(() => {
          const { day, hour, minute, second } = countdown(
            this.items[index].finish_time
          );
          if (Number(day) == 0 && Number(hour) == 0 && Number(minute) == 0 && Number(second) == 0){
            return this.items[index].is_okday = false;
          }
          if (Number(day) > 0) {
            this.items[index].is_okday = false;
            this.items[index].countdowndesc = "";
          } else {
            this.items[index].is_okday = true;
            this.items[
              index
            ].countdowndesc = `${hour}小时${minute}分钟${second}秒`;
          }
          this.$forceUpdate();
        }, 1000);
      }
    });
  }
}
