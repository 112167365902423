import { RouteConfig } from "vue-router";
import Welfare from "@/views/welfare/Welfare.vue";
import Mine from "@/views/mine/Mine.vue";
import Store from "@/views/special-sales/Store.vue";

export const LayoutRouter: Array<RouteConfig> = [
  {
    path: "/",
    name: "Store",
    component: Store,
    meta: {
      title: "超级蒸汽商城",
      desc: "商城",
      class: "__theme_white",
      footer: true,
    },
  },
  {
    path: "/welfare",
    name: "Welfare",
    component: Welfare,
    meta: {
      title: "超级蒸汽商城",
      desc: "福利",
      class: "__theme_white",
      footer: true,
    },
  },
  {
    path: "/mine",
    name: "Mine",
    component: Mine,
    meta: {
      title: "超级蒸汽商城",
      desc: "我的",
      class: "__theme_white",
      footer: true,
    },
  },
];
