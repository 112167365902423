import { render, staticRenderFns } from "./WelfareBarrageItem.vue?vue&type=template&id=4036a0ed&scoped=true&"
import script from "./WelfareBarrageItem.vue?vue&type=script&lang=ts&"
export * from "./WelfareBarrageItem.vue?vue&type=script&lang=ts&"
import style0 from "./WelfareBarrageItem.vue?vue&type=style&index=0&id=4036a0ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4036a0ed",
  null
  
)

export default component.exports