import axios from "axios";
import { IAddress } from "@/api/mine.api";
import { CharacterDetail } from "@/api/voucher.api";

export default class BlindBoxApi {
  static async getBlindListOne(box_id: number): Promise<IBlindBoxItemActive> {
    return axios.get(`mall-blind-box/list/${box_id}`);
  }

  static async getBlindSettingActivity(): Promise<SettingBlindBoxActivity[]> {
    return axios.get(`mall-blind-box/activity/setting`);
  }

  static async getBlindList(
    page = 1,
    page_size = 10
  ): Promise<IBlindBoxItem[]> {
    return axios.get("/apiv2/v2/blind-box/list", {
      params: {
        page,
        page_size,
        hide_price: 1,
      },
    });
  }

  static async applyDeliver(
    id: number | string,
    address: IAddress
  ): Promise<void> {
    return axios.post(`/apiv2/v2/blindbox/deliver/${id}`, address);
  }

  static async getDeliver(
    type = 1,
    page = 1,
    page_size = 10
  ): Promise<Deliver[]> {
    return axios.get("/apiv2/v2/blindbox/deliver", {
      params: {
        type,
        page,
        page_size,
      },
    });
  }

  static async getDeliverDetail(id: string): Promise<DeliverDetail> {
    return axios.get(`/apiv2/v2/blindbox/deliver/${id}`);
  }
  static async testConpon() {
    return axios.get(`/registered-welfare/path`);
  }

  static async getlotteryData(page1 = 0,page_size1 = 10){
    return axios.get(`/apiv2/lottery`,{
      params: {
        page:page1,
        page_size:page_size1,
      },
    });
  }
  static async getCounponData(page = 0,page_size = 10){
    return axios.get(`/cap-store/coupon-products`,{
      params: {
        page,
        page_size,
      },
    });
  }
  static async getRankList(){
    return axios.get(`/activity/consumer-rankings`);
  }
  static async getIndexList(){
    return axios.get(`/home/showcase`);
  }
  static async getMyRecyleSupply(page:number,page_size:number,supply_ids?:string){
    return axios.get(`/mall-voucher/mine/recycle`,{
      params:{
        page,
        page_size,
        supply_ids,
      }
    });
  }
  static async toRecyleSupply(supplys:any){
    return axios.post(`/mall-voucher/mine/recycle`,{
      supplys
    });
  }
}

export interface DeliverDetail extends IAddress {
  cover: string;
  price: number;
  address_id: number;
  express_name: string;
  sku_id: number;
  express_number: string;
  express_phone: string;
  status_text: string;
  title: string;
  status: number;
  character: CharacterDetail;
}

export interface DeliverCharacterItem {
  color: string;
  background: string;
  text: string;
  value: number;
}

export interface Deliver {
  cover: string;
  character: DeliverCharacterItem;
  create_time: number;
  user_id: number;
  price: number;
  sku_id: number;
  id: number;
  status_text: string;
  title: string;
}

export interface DiscountTags {
  background: string;
  border: string;
  color: string;
  str: string;
}

export interface IBlindBoxItem {
  id: number;
  title: string;
  cover: string;
  start_time: number;
  end_time: number;
  price: number;
  original_price: number;
  discount_tags: DiscountTags[];
  point: number;
  totle_text: string;
  is_today_free: boolean;
  box_status: number;
  supply_num: number;
  rank_num: number;
  pay_type: boolean;
  worth: string;
  pictures: string[];
  background: string[];
  url: string;
  ribbon: string[];
  footer: string;
  code_conduct: string;
  code_conduct_ios: string;
}
export interface IBlindBoxItemActive extends IBlindBoxItem {
  box_inventory: number;
}

export interface SettingBlindBoxActivity {
  box_id: number;
  finish_time: number;
  start_time: number;
  library: string[];
  empty_tip: string;
  empty_confirm: string;
}
