












import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { MallHomeDiscountBanner } from "@/api/cap-store.api";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {},
})
export default class fracturePrice extends Vue {
  @Prop() private items!:MallHomeDiscountBanner
  created(){
  }
  toBuyIt(){
    // if( this.items.behavior_data.code == 4){
    //   if (InteractionLib.isApp()) {
    //   return InteractionLib.openWebView(`${location.origin}/sp-store/${this.items.banner_id}`);
    // }
    //   window.location.href = this.items.behavior_data.data

    // }
}
}
