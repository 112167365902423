

















import { Component, Prop, Vue } from "vue-property-decorator";
import { getCharacter, getCharacterColor } from "@/utils/common.util";
import { ISupplyShufflingMessage } from "@/api/supply-package.api";

@Component({})
export default class WelfareBarrageItem extends Vue {
  @Prop({ default: false }) private readonly animation!: boolean;
  @Prop() private readonly item!: ISupplyShufflingMessage;

  get character(): number {
    return this.item.character;
  }

  get characterText(): { icon: string; text: string } {
    const text = getCharacter(this.character, "", true);
    const icon =
      this.character < 5 ? "" : `__character_peak_${this.character - 4}_icon`;
    return {
      text,
      icon,
    };
  }

  get characterStyle(): Record<string, string> {
    return getCharacterColor(this.character);
  }
}
