import { RouteConfig } from "vue-router";

export const AuthRouter: Array<RouteConfig> = [
  {
    path: "/authorization/:uri",
    name: "Authorization",
    component: () => import("@/views/auth/Authorization.vue"),
    meta: {
      title: "授权登录",
      desc: "微信授权登录回调页面",
      class: "__theme_white",
    },
  },
];
