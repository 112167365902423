import axios from "axios";
import { IAddress } from "@/api/mine.api";
import { CharacterDetail } from "@/api/voucher.api";

export default class MemberApi {
    static async getMemberData() {
        return axios.get(`/member-card/home`);
      }
      static async getRecentlyGame() {
        return axios.get(`/member-card/gift/three`);
      }
      static async checkMemberIs(){
        return axios.get(`/member-card/gold/status`);
      }
      static async recycleReword(gift_status:number){
        return axios.post(`/member-card/gift/receive`,{ gift_status })
      }
      static async checkRecycleReword(){
        return axios.get(`/member-card/gift/receive`)
      }
      static async checkSuccessBlind(){
        return axios.get(`/member-card/gift/receive/blind-box`)
      }
      static async getMineData(){
        return axios.get(`/member-card/mine`)
      }
}