



























import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { IUserInfo } from "@/types/global-state.type";

@Component({})
export default class UserMedia extends Vue {
  globalState = getModule(GlobalStateStore);
  @Prop() userInfoImg!:any

  @Emit()
  handleClick(): void {
    return;
  }
pic = [
    "https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/member/%E5%BE%BD%E7%AB%A0-%E9%87%91%E4%BC%9A%E5%91%98.png",
    "https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/member/%E5%BE%BD%E7%AB%A0Steam.png",
    "https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/member/%E5%BE%BD%E7%AB%A0PS.png",
  ];
  get isLogin(): boolean {
    return !!this.globalState.userToken;
  }

  get userInfo(): IUserInfo | null {
    return this.globalState.userInfo;
  }

  get profileStyle(): string {
    const { member_img = "" } = this.userInfo || {};
    return this.isLogin ? `background-image: url(${member_img});` : "";
  }

  get userNickname(): string {
    const { member_nickname = "" } = this.userInfo || {};
    return this.isLogin ? member_nickname : "未登录";
  }

  get userSignature(): string {
    return this.isLogin
      ? "超级蒸汽，让游戏更有趣～"
      : "立即登录，让游戏更有趣~";
  }
}
