













import { MallHomeGridBanner } from "@/api/cap-store.api";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class smallStore extends Vue {
  @Prop() items!: any;
  bannerList: any = [];
  isflutter: boolean = false;
  created() {
    this.isflutter = !!InteractionLib.isApp();
    this.bannerList = this.items;
    if (this.bannerList.length) {
      if (this.bannerList.length < 3) {
        for (let i = this.bannerList.length; i < 3; i++) {
          this.bannerList.push([]);
        }
      } else if (this.bannerList.length > 3 && this.bannerList.length < 6) {
        for (let i = this.bannerList.length; i < 6; i++) {
          this.bannerList.push([]);
        }
      }
    }
  }
  tobuyit(item: any) {
    item = JSON.parse(JSON.stringify(item))
        console.log(item.behavior_data.code);

    if (item.behavior_data.code == 4) {
      if (InteractionLib.isApp()) {

        return InteractionLib.openWebView(item.behavior_data.data);
      }
      window.location.href = item.behavior_data.data;
     
    }
     if (item.behavior_data.code == 401) {
        if (InteractionLib.isApp()) {
          return InteractionLib.router(
            20,
            JSON.stringify({
              url: item.behavior_data.data,
            })
          );
        }
        window.location.href = item.behavior_data.data;
      }
  }
}
