import axios from "axios";
import { IAddress } from "@/api/mine.api";
import { CharacterDetail } from "@/api/voucher.api";

export default class BazaarApi {
  static async getBazaarList({
    page = 1,
    page_size = 10,
    sort = 0,
    filter = 0,
  }): Promise<Bazaar[]> {
    return axios.get("/bazaar", {
      params: {
        sort,
        filter,
        page,
        page_size,
      },
    });
  }
  static async getNewPeopleState(){
    return axios.get("registered-welfare/path")
  }
  static async getProductDetail(detail_id: string): Promise<ProductDetail[]> {
    return axios.get(`/bazaar/detail/${detail_id}`);
  }
  static async getProductWelfare(detail_id: string): Promise<ProductDetail[]> {
    return axios.get(`/mall-blind-box/product/${detail_id}`);
  }

  static async getPlayerList(detail_id: string) {
    return axios.get(`/bazaar/detail/${detail_id}/entrust`);
  }
  static async getMoney(): Promise<WalletMoney> {
    return axios.get(`/wallet/money`);
  }

  static async payMoney(detail_id: string, option: PayParams):Promise<MoneyDetail>  {
    return axios.post(`/bazaar/order/${detail_id}`, option);
  }
  static async getMoneyState(order_no: string) {
    return axios.get(`/bazaar/order/${order_no}`);
  }

  static async getMydemand(page =  1,page_size = 10,type = 0) {
    return axios.get(`/bazaar/entrust`, {
      params: {
        type,
        page,
        page_size
      },
    });
  }

  static async cancelDemand(detail_id: number) {
    return axios.delete(`/bazaar/entrust/${detail_id}`);
  }
  static async getBlindBoxFree() {
    return axios.get(`mall/blind-box/daily-free`);
  }
  static async getRepertory(page = 1,page_size = 10) {
    return axios.get(`/bazaar/repertory`,{
      params: {
        page,
        page_size
      }
    });
  }

  static async getRepertorying(page = 1,page_size = 10) {
    return axios.get(`/bazaar/sales`, {
      params: {
        type:0,
        page,
        page_size
      },
    });
  }
 
  static async cancelRepertorying(detail_id: any) {
    return axios.delete(`/bazaar/sales/${detail_id}`);
  }
  static async getMineLog(page=1,page_size=10):Promise<MineLogIn[]>{
    return axios.get(`/mall-voucher/mine/log`,{
      params:{
        page,
        page_size
      }
    });
  }
  static async getTaskMessage() {
    return axios.get(`/apiv2/config`);
  }
  static async getSellRecordList(page = 1,page_size = 10) {
    return axios.get(`/bazaar/sales/`, {
      params: {
        type:1,
        page,
        page_size
      },
    });
  }

  static async toSellCard(detail_id: any, price: any) {
    return axios.post(`/bazaar/sales/${detail_id}`, {
      money: (Number(price) * 1000)/10,
    });
  }
  static async getMoneyDetail(page = 1,page_size = 10) {
    return axios.get(`/wallet/money/record`, {
      params:{
        page,
        page_size
      }
    });
  }
  static async signDailyDay():Promise<SignData>{
    return axios.post(`apiv2/v3/mine/reward/point/daily`)
  }

  static async messageList():Promise<SignData>{
    return axios.get(`apiv2/welfare/config`)
  }
  static async getBannerPic():Promise<SignData>{
    return axios.get(`apiv2/index/banner`,{params:{
      type:21
    }})
  }

  static async getBannerAppPic():Promise<SignData>{
    return axios.get(`apiv2/index/banner`,{params:{
      type:8
    }})
  }
  static async mallLog(page = 1,page_size = 10){
    return axios.get(`/mall-voucher/mine/log`, {
      params:{
        page,
        page_size
      }
    })
  }
  static async getArticePage(id:any){
    return axios.get(`/apiv2/home/article/${id}`)
  }
  static async getRegisteredWelfare(){
    return axios.get(`/registered-welfare`)
  }
  static async getRegisteredWelfareDetail(){
    return axios.get(`registered-welfare/detail`)
  }
  static async getUnSign(){
    return axios.get(`/daily-reward`)
  }
  static async GoSign(){
    return axios.post(`/daily-reward`)
  }
}
export interface SignData {
  days: number
  exp: number
  max_exp: number
  max_point: number
  point: number
}
export interface MineLogIn {
  background: string
  color: string
  id: number
  remark: string
  source: number
  source_text: string
  title: string
  type: number
  type_text: string
  voucher_code: number
  voucher_group: number
}
export interface MoneyDetail {
  channel: number
  deduction_wallet: boolean
  goods_detail: {goods_id: number, goods_name: string, quantity: number, price: number}
  order_no: string
  order_price: number
  pay_channel: number
  point: number
  type: number
  wallet_price: number
}
export interface Bazaar {
  cover: string;
  total: number;
  min_price: number;
  target_data_id: number;
  target_type: number;
  id: number;
  detail_id: string;
  title: string;
}

export interface PayParams {
  money: number;
  pay_channel: number;
  deduction_wallet: boolean;
  appid?: string;
  openid?: string;
}
export interface ProductDetail {
  target_id: number;
  detail_type: number;
  detail_id: string;
  title: string;
  cover: string;
  detail: string;
  price: number;
  character: any;
  sale_min_price: number;
  entrust_max_price: number;
}
export interface WalletMoney {
  balance_money: number;
  freeze_money: number;
  audit_money: number;
  demand_money: number;
}
