

























































import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import FracturePrice from "@/views/special-sales/components/fracturePrice.vue";
import DailyDiscount from "@/views/special-sales/components/DailyDiscount.vue";
import SmallStore from "@/views/special-sales/components/smallStore.vue";
import TimeLimit from "@/views/special-sales/components/TimeLimit.vue";
import HotSales from "@/views/special-sales/components/HotSales.vue";
import { SwipeItem, Swipe, Lazyload } from "vant";
import { MallHome } from "@/api/cap-store.api";
import InteractionLib from "@/utils/interaction.lib";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    FracturePrice,
    SmallStore,
    SwipeItem,
    Swipe,
    TimeLimit,
    DailyDiscount,
    HotSales,
  },
})
export default class NewStore extends Vue {
  @Prop() private StoreDeatil!: MallHome;
  @Prop() isVipMember!: boolean;
  images = [
    "https://img01.yzcdn.cn/vant/apple-1.jpg",
    "https://img01.yzcdn.cn/vant/apple-2.jpg",
  ];
  isflutter = false;
  globalState = getModule(GlobalStateStore);

  async created() {
    this.isflutter = !!InteractionLib.isApp();
    console.log(this.StoreDeatil,'StoreDeatilStoreDeatilStoreDeatilStoreDeatil')
  }

  get audit_version() {
    return this.globalState.audit_version;
  }


  tobuyit(item: any) {
    if (item.behavior_data.code == 4) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(item.behavior_data.data);
      }
      window.location.href = item.behavior_data.data;
    }
    if (item.behavior_data.code == 401) {
      if (InteractionLib.isApp()) {
        return InteractionLib.router(
      20,
      JSON.stringify({
        url: item.behavior_data.data,
      })
    );
      }
      window.location.href = item.behavior_data.data;
    }
  }

  get showTaskFlag() {
    return this.isflutter;
  }
}
