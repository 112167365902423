<template>
  <div class="sales-mini-list __no-select" @click.stop="closetotal">
    <div class="wrap_" v-show="popState" style="z-index: 9999">
      <PopItems class="popitem" @closePop="closePop" />
    </div>
    <div class="oval"></div>
    <div class="sales-header __flex __flex_ac">
      <div style="color: #121212; font-weight: 800">当前在售</div>
      <div class="__flex_1" />
      <div class="sales-all">
        <div v-if="updownState" class="flex-row" @click="updown">
          <span>降序</span>
          <img
            src="https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/Official-website/down.png"
            alt=""
          />
        </div>
        <div v-else class="flex-row" @click="updown">
          <span>升序</span>
          <img
            src="https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/Official-website/up.png"
            alt=""
          />
        </div>
        <div style="color: #dddddd">｜</div>
        <div class="flex-row" @click.stop="popStateHandler">
          <span>筛选</span>
          <img
            src="https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/Official-website/many.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <List
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="this.saleList.length > 0"
    >
      <div class="sales-content">
        <div class="SALES-CONTENT">
          <div
            class="Items"
            :style="{
            width:(wideHigh-6)+'px',
            background:'white'}"
            v-for="(item, index) in saleList"
            :key="index"
            @click="toCardDetail(item.detail_id,item.product_id)"
          >
            <img
              :src="item.cover"
              alt=""
              :style="{height:(wideHigh-6)+'px'
            ,width:(wideHigh-6)+'px'}"
              class="imgsproduct"
            />
            <div class="flex-column">
            <div class="promise">玩家交易&nbsp;&nbsp;&nbsp;平台担保</div>
            <div class="title">{{item.title}}</div>
            <div class="price" style="margin-top:6px">
                <div>商城价¥{{item.mall_price}}</div>
                <div style="margin-left:4px;margin-right:4px">|</div>
                <div>官方价¥{{item.official_price}}</div>
            </div>
            <div class="redprice" style="margin-top:6px">
                <div style="font-size:12px">¥</div>
                <div style="margin-left:3px;font-size:16px">{{item.min_price}}</div>
                <div style="margin-left:2px;font-size:12px;margin-top:2px;">起</div>
            </div>
        </div>
           
          </div>
        </div>
      </div>
    </List>
    <EmptyBox class="empty" v-else tip="集市被扫荡一空" />
  </div>
</template>

<script>
import EmptyBox from "@/components/EmptyBox.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import PopItems from "@/views/special-sales/components/PopItems.vue";
import { List } from "vant";
import { StoreProduct } from "@/api/cap-store.api";
import { getNow } from "@/utils/common.util";
import SearchApi, { Bazaar } from "@/api/bazaar.api";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    PopItems,
    EmptyBox,
    List,
  },
})
export default class SalesMiniList extends Vue {
  updownState = true;
  popState = false;
  loading = false;
  finished = false;
  saleList = [];
  page = 1;
  page_size = 10;
  filter = 0;
  sort = 2;
  toCardDetail(id,ids) {
    if(ids == -1){
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/market?id=${id}`);
      }
      this.$router.push({
        name: "market",
        query: {
          id,
        },
      });
    }else{
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/sp-store/${ids}?tab=1`);
      }
      this.$router.push({
        name: "StoreDetail",
        params: {
          id:ids,
        },
        query: {
          tab: 1
        }
      });
    }
  }

  handleJumpList() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/special/sales`);
    }
    this.$router.push({
      name: "SpecialSales",
      query: { time: String(getNow()) },
    });
  }

  async updown() {
    this.page = 1;
    this.updownState = !this.updownState;
    if (this.updownState) {
      this.sort = 2;
    } else {
      this.sort = 1;
    }

    let res = await SearchApi.getBazaarList({
      sort: this.sort,
      page: this.page,
      page_size: this.page_size,
      filter: this.filter,
    });
    this.finished = false;
    this.saleList = res;
  }

  popStateHandler() {
    this.popState = !this.popState;
  }

  async onLoad() {
    this.finished = false;
    this.page = this.page + 1;
    let res = await SearchApi.getBazaarList({
      sort: this.sort,
      page: this.page,
      page_size: this.page_size,
      filter: this.filter,
    });
    this.loading = false;

    res.forEach((item, index) => {
      this.saleList.push(item);
    });
    if (res.length < 1) {
      this.finished = true;
    }
  }

  async closePop(e) {
    this.finished = false;
    this.page = 1;
    this.filter = e - 1;
    let res = await SearchApi.getBazaarList({
      sort: this.sort,
      page: this.page,
      page_size: this.page_size,
      filter: this.filter,
    });
    this.saleList = res;
    this.popState = false;
  }

  closetotal() {
    if (this.popState) {
      this.popState = false;
    }
  }
  widthheight = {
    width: 20+'px',
    height: 20+'px',
  };
  wideHigh = 20;
  async created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 12;
    this.widthheight = {
      width: this.wideHigh + 'px',
      height: this.wideHigh + 'px',
    };
    let res = await SearchApi.getBazaarList({
      page: this.page,
      page_size: this.page_size,
      sort: this.sort,
    });
    this.saleList = res;
  }
}
</script>
<style lang="scss" scoped>
.sprice{
    font-size: 10px;
    color: red;
}
.imgsproduct {
  object-fit: cover;
  border-radius: 4px;
}
.sales-mini-list {
  margin-top: -130px;
  padding: 10px 0;
  position: relative;
  z-index: 99;

  .oval {
    height: 150px;
    width: 100%;
    position: absolute;
    top: -10px;
    z-index: -1;
    background: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
    border-radius: 12px 12px 0px 0px;
  }

  .sales-header {
    padding: 0 13px 16px 12px;

    .sales-logo {
      flex-shrink: 0;
      width: 84px;
      height: 16px;
    }

    .sales-all {
      flex-shrink: 0;
      font-size: 12px;
      font-weight: 400;
      width: 95px;
      color: #878e99;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .sales-content {
    padding: 0 12px;

    .SALES-CONTENT {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .Items {
      margin-bottom: 8px;
      border-radius: 6px;
      .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          color: #f83737;
          font-weight: 800;
        }
      }

      .stock {
        font-size: 12px;
        color: #878e99;
      }

      .name {
        font-size: 14px;
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .empty {
    height: 400px;
    margin-top: 70px;
  }
}

.wrap_ {
  position: relative;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba($color: #000000, $alpha: 0.3);

  .popitem {
    position: absolute;
    top: 120px;
    right: 12px;
    z-index: 9999;
  }
}
.flex-column{
    flex-direction: column;
    padding:12px 12px;
    position: relative;
}
.title{
  white-space:nowrap; 
  overflow:hidden; 
  text-overflow:ellipsis;
  color: black;
  font-size: 13px;
  font-weight: 800;
  height: 16px;
}
.price{
    display: flex;
    color: #969696;
    font-size: 10px;

}
.redprice{
    display: flex;
    color: red;
    align-items: center;
    font-weight: 800;
}
.spprice{
    background: red;
    width: 32px;
    height: 16px;
    font-size: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-left: 2px;
}
.promise{
  position: absolute;
  background: linear-gradient(90deg, #FF8500 0%, #FF0000 100%);;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  top: -25px;
  left: 0;
  color: white;
  font-weight: 800;
}
</style>
