























import { Component, Prop, Vue } from "vue-property-decorator";
import { List } from "vant";
import CapStoreApi from "@/api/cap-store.api";
import ProductCard from "@/views/SpecialSale/components/ProductCards.vue";

@Component({
  components: { List, ProductCard },
})
export default class ProductList extends Vue {
  @Prop({ default: 0 }) private readonly tabId!: number;
  @Prop() isVipMember!:boolean;
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;
  product: any = [];

  async loadData(): Promise<void> {
    this.page++;
    const { tabId, page, page_size, product } = this;
    let rows: any = await CapStoreApi.getHotItemList(
      tabId,
      page,
      page_size
    ).catch(() => []);
    this.product = rows.list;
    this.loading = false; //请求
    if (!rows.list.length || rows.list.length < this.page_size)
      this.finished = true;
    this.product = page > 1 ? [...product, ...rows.list] : rows.list;
  }

  widthheight = {};
  contentWidth = {};
  wideHigh = 0;
  active = 0;

  async created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 16;
    this.contentWidth = {
      width: this.wideHigh + "px",
      height: this.wideHigh + "px",
    };
    this.widthheight = {
      width: this.wideHigh + "px",
    };
  }
}
