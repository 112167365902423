<template>
  <div class="search" @click="toSearch">
    <Icon color="#fff" name="search" class="icon" size="20" />
    <div class="input">搜索</div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Icon } from "vant";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { Icon },
})
export default class Search extends Vue {
  toSearch() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/search`);
    }
    this.$router.push({
      name: "search",
    });
  }
}
</script>

<style lang="scss" scoped>
.search {
  height: 32px;
  width: 93%;
  background: #3f4755;
  border-radius: 18px;
  display: flex;
  align-items: center;
  .icon {
    margin-left: 10px;
  }
  .input {
    background: #3f4755;
    border: none;
    width: 80%;
    color: rgba($color: #fff, $alpha: 0.5);
    margin-left: 5px;
    font-size: 13px;
  }
}
</style>
