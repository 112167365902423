



















import { Component, Vue, Watch } from "vue-property-decorator";
import BlindBoxItem from "@/views/welfare/components/BlindBoxItem.vue";
import WelfareHead from "@/views/welfare/components/WelfareHead.vue";
import WelfareBlindBox from "@/views/welfare/components/WelfareBlindBox.vue";
import SignPop from "./components/SignPop.vue";
import BazzarApi from "@/api/bazaar.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import moment from "moment";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";

@Component({
  components: { WelfareBlindBox, WelfareHead, BlindBoxItem, SignPop },
})
export default class Welfare extends Vue {
  globalState = getModule(GlobalStateStore);
  hiddenUserOrder = false;

  mounted() {
    if (InteractionLib.isApp()) {
      const activity = (this.$route.query.activity as unknown as string) || "";
      this.hiddenUserOrder = Boolean(activity);
    }
    observer("refreshInit", async () => {
      if (!this.globalState.userInfo) {
        await this.globalState.getUserInfo();
      }
    });
  }
  toOrder() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-order`);
    }
    this.$router.push({ name: "MyOrder" });
  }

  get userInfo() {
    return this.globalState.userInfo;
  }

  async created() {
    if (InteractionLib.isApp()) {
      InteractionLib.getUserInfo();
    }
  }
}
