






import { Component, Vue } from "vue-property-decorator";
import WelfareBarrage from "@/views/welfare/components/WelfareBarrage.vue";
import SupplyPackageApi, {
  ISupplyShufflingMessage,
} from "@/api/supply-package.api";

@Component({
  components: { WelfareBarrage },
})
export default class WelfareSwiper extends Vue {
  message: ISupplyShufflingMessage[] = [];

  async mounted(): Promise<void> {
    this.message = await SupplyPackageApi.getSupplyShufflingMessage(
      undefined,
      50
    );
  }
}
