import axios from "axios";
import { IAddress } from "@/api/mine.api";

export default class CapStoreApi {
  static async getSpecialSale(
    page = 1,
    page_size = 10
  ): Promise<StoreProduct[]> {
    const params = { page, page_size };
    return axios.get("/apiv2/special/sale", { params });
  }

  static async getCategoryList(): Promise<StoreProductCategory[]> {
    return axios.get("/apiv2/cap/category-list");
  }

  static async getProductListByCategory(
    id: number,
    page = 1,
    page_size = 10
  ): Promise<Product[]> {
    return axios.get("/apiv2/cap/store/category", {
      params: {
        id,
        page,
        page_size,
      },
    });
  }

  static async getProduct(id: number): Promise<StoreProductDetail> {
    return axios.get(`/apiv2/cap/store/${id}`);
  }

  static async getProductBuyRecord(
    id: number,
    order_no: string
  ): Promise<OrderRecord> {
    return axios.get(`/apiv2/cap/store/${id}/order`, {
      params: { order_no },
    });
  }

  static async buyProduct(
    id: number,
    channel = 0,
    openid?: string,
    deduction_wallet?:boolean,
    order_address?: IAddress
  ): Promise<StoreBuyProductResult> {
    const params: Record<string, any> = {
      channel,
      openid,
      deduction_wallet
    };
    if (order_address) params.order_address = order_address;
    return axios.post(`/apiv2/cap/store/${id}`, params);
  }

  static async getProductList(
    page = 1,
    page_size = 10
  ): Promise<Array<StoreProduct>> {
    return axios.get("/apiv2/cap/store", { params: { page, page_size } });
  }

  static async recordVisitLog(id: number): Promise<void> {
    return axios.get(`/apiv2/cap/record/visit/${id}`);
  }

  static async getOrderCheckout(goods_id: number): Promise<OrderCheckout> {
    return axios.get(`/apiv2/order/checkout`, { params: { goods_id } });
  }
  static async getStore(): Promise<MallHome> {
    return axios.get(`/mall/home`);
  }
  static async getHotItemList(id:number,page:number,page_size:number){
    return axios.get(`/mall/subject/${id}`,{ params: { page, page_size } });
  }
}

export interface MallHomeBehaviorData {
  code: number;
  data: string;
}

export interface MallHomeDiscountBanner {
  banner_id: number;
  banner_img: string;
  banner_skip_type: number;
  type_id: number;
  behavior_data: MallHomeBehaviorData;
  title?: any;
  end_time: number;
  url: string;
  code_conduct: string;
  code_conduct_ios: string;
}

export interface MallHomeBehaviorData2 {
  code: number;
  data: string;
}

export interface MallHomeGridBanner {
  banner_id: number;
  banner_img: string;
  banner_skip_type: number;
  type_id: number;
  behavior_data: MallHomeBehaviorData2;
  title?: any;
  end_time: number;
  url: string;
  code_conduct: string;
  code_conduct_ios: string;
}

export interface MallHomeHotSubject {
  id: number;
  title: string;
  cover: string;
  count: number;
}

export interface MallHomeSubjectTab {
  subject_id: number;
  title: string;
  sort_num: number;
}

export interface MallHomeSpecialSale {
  is_okday: boolean;
  id: number;
  timeDesc:any;
  countdowndesc:any;
  gold_price: number;
  title: string;
  cover: string;
  gold_unique: boolean;
  bazaar_price: number;
  inventory: number;
  point: number;
  countdown: number;
  describe: string;
  start_time: number;
  finish_time: number;
  bazaar_text: string;
  pay_type: number;
  price: number;
  discount_tag: string;
  category: number;
  target_id: number;
  sale_price: number;
  is_sale: boolean;
  is_gold_price: boolean;
  sale_price_expire_time: number;
  image: string;
  home_sort: number;
  readyFlag:boolean;
  repertory: number;
  code_conduct: string;
  code_conduct_ios: string;
  code_conduct_flutter: string;
}

export interface MallHomeBehaviorData3 {
  code: number;
  data: string;
}

export interface MallHomeBanner {
  banner_id: number;
  banner_img: string;
  banner_skip_type: number;
  type_id: number;
  behavior_data: MallHomeBehaviorData3;
  title?: any;
  end_time: number;
  url: string;
  code_conduct: string;
  code_conduct_ios: string;
}

export interface MallHome {
  discount_banner: MallHomeDiscountBanner[];
  grid_banner: MallHomeGridBanner[];
  hot_subject: MallHomeHotSubject[];
  subject_tabs: MallHomeSubjectTab[];
  special_sale: MallHomeSpecialSale[];
  banner: MallHomeBanner[];
}

export interface OrderCheckoutProductItem {
  cover: string;
  price: number;
  num: number;
  name: string;
  point: number;
}

export interface OrderCheckout {
  product: Array<OrderCheckoutProductItem>;
  total_price: number;
  actual_price: number;
  discount: number;
  pay_type: number;
  point: number;
}

export interface Product {
  countdown: boolean;
  bazaar_price: number;
  target_id: number;
  code_conduct_ios: string;
  title: string;
  inventory: number;
  repertory: number;
  point: number;
  finish_time: number;
  cover: string;
  bazaar_text: string;
  start_time: number;
  price: number;
  pay_type: number;
  discount_tag: string;
  id: number;
  describe: string;
  category: number;
  code_conduct: string;
}

export interface StoreProductCategory {
  id: number;
  name: string;
}

export interface StoreProduct {
  id: number;
  title: string;
  cover: string;
  bazaar_price: number;
  point: number;
  describe: string;
  start_time: number;
  finish_time: number;
  repertory: number;
  pay_type: number;
  price: number;
  bazaar_text: string;
  inventory: number;
  discount_tag: string;
  gold_price: number;
  gold_unique: boolean;
}

export interface StoreProductDetail {
  force_tip: any;
  presell: boolean;
  gold_price: number;
  gold_unique: any;
  is_okday: boolean;
  countdowndesc: string;
  timeDesc: string;
  sale_price_expire_time: number;
  readyFlag: boolean;
  home_sort:number;
  id: number;
  title: string;
  describe: string;
  cover: string;
  image: string;
  bazaar_price: number;
  limit_number: number;
  limit_day: number;
  html: string;
  type: number;
  sale_price: number;
  point: number;
  status: boolean;
  voucher_group: number;
  start_time: number;
  finish_time: number;
  repertory: number;
  countdown?: boolean;
  bazaar_text: string;
  price: number;
  inventory: number;
  discount_tag: string;
  pay_type: ProductPayType;
}

export enum ProductPayType {
  point,
  money,
}

export interface GoodsDetail {
  goods_id: number;
  goods_name: string;
  quantity: number;
  price: number;
}

export interface StoreBuyProductResult {
  order_no: string;
  point: number;
  type: number;
  order_price: number;
  goods_detail: GoodsDetail;
  alipay?: string;
  wechatPay?: any;
}

export interface Order {
  id: number;
  order_no: string;
  product_type: number;
  order_status: number;
  payment_status: number;
  deliver_status: number;
  refund_status: number;
  product_number: number;
  product_total_price: number;
  order_price: number;
  logistics_price: number;
  address_id: number;
  pay_channel: number;
  pay_no: string;
  pay_time: number;
  deliver_time: number;
  create_time: number;
}

export interface Detail {
  id: number;
  goods_id: number;
  product_name: string;
  product_cover: string;
  product_price: number;
  product_barcode: string;
  product_model_info: string;
  product_model_param: string;
  buy_number: number;
}

export interface Deduction {
  id: number;
  title: string;
  proportion: number;
  exchange_number: number;
  exchange_price: number;
}

export interface Remark {
  id: number;
  remark: string;
  status: number;
}

export interface Refund {
  address_id: number;
  apply_price: number;
  content: string;
  finish_time: number;
  id: number;
  order_deduction_id: number;
  order_price: number;
  reason: string;
  refund_time: number;
  status: number;
  type: number;
}

export interface Status {
  name: string;
  index: number;
  remarkStatus: number;
}

export interface OrderRecord {
  order: Order;
  detail: Detail;
  deduction: Deduction;
  remark: Remark[];
  status: Status;
  refund?: Refund;
}
