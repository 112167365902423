













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class EmptyBox extends Vue {
  @Prop({ default: "" }) private readonly tip!: string;
  @Prop({ default: "" }) private readonly haveChange!: string;

}
