import { render, staticRenderFns } from "./FooterItem.vue?vue&type=template&id=e22beb44&scoped=true&"
import script from "./FooterItem.vue?vue&type=script&lang=ts&"
export * from "./FooterItem.vue?vue&type=script&lang=ts&"
import style0 from "./FooterItem.vue?vue&type=style&index=0&id=e22beb44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e22beb44",
  null
  
)

export default component.exports