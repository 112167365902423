




















import { Component, Vue, Watch } from "vue-property-decorator";
import FooterItem from "@/layout/components/FooterItem.vue";
import { Route } from "vue-router";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { FooterItem },
})
export default class DefaultLayout extends Vue {
  footer = false;
  footerItems = [
    { name: "Store", icon: "sale", title: "商城" },
    { name: "Welfare", icon: "welfare", title: "福利" },
    { name: "Mine", icon: "mine", title: "我的" },
  ];
  footerActive = "";

  get currentRoute(): Route {
    return this.$route;
  }

  @Watch("currentRoute", { immediate: true })
  watchCurrentRoute(route: Route): void {
    if (InteractionLib.isApp()) {
      this.footer = false;
      return;
    }
    const { footer = false } = route.meta || {};
    if (this.footer !== Boolean(footer)) {
      this.footer = footer;
    }
    this.footerActive = route.name || "";
  }

  handleFooterClick(name: string): void {
    if (this.$route.name === name) return;
    this.$router.replace({ name });
  }
}
