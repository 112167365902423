<template>
 <div style="margin-top:12px;">
   <div class="daily-discount-component"  v-if="DailyDiscountList">
    <img
    @click="toDailyDiscount"
      class="daily-discount-side-img"
      src="https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/market/%E5%A4%A9%E5%A4%A9%E9%AA%A8%E6%8A%98-%E6%A0%87%E9%A2%98%402x.png"
      alt=""
    />
    <div class="scroll-x">
      <div
        class="daily-discount-card-item"
        v-for="(item, index) in DailyDiscountList"
        :key="index"
        @click="toDailyDiscount"
      >
        <img class="daily-discount-card-img" :src="item.image" alt="" 
        />
        <div style="
        background:rgba(0,0,0,0.55);color:#D1D1D1;width:100%;height:20px;position:absolute;top:55px;left:0;right:0;display:flex;align-items:center;justify-content:center;font-size:9px;">Steam官方价¥{{item.bazaar_price}}</div>
        <div class="daily-discount-card-price"
        >
          <div class="card-price">
            <div>
              <span style="font-size: 10px; color: #e02d38">¥</span>
              <span style="font-size: 14px; color: #e02d38;margin-left:4px;">{{item.price}}</span>
            </div>
            <!-- <span style="font-size: 9px;color: #b46f73;text-decoration: line-through;margin-right:4px;">¥988</span> -->
            <div class="toBuyprice">
               去购买
            </div>
          </div>
            
          
        </div>
      </div>
      <div style="width:30px;height:50px;"> &nbsp;</div>
    </div>
  </div>
   <div class="blind-box-component" style="margin-top:12px;"  v-if="BlindBoxList">
    <img
     @click="toBlindBox(false)"
      class="blind-box-side-img"
      src="https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/market/%E6%A0%87%E9%A2%98-%E8%B6%85%E5%80%BC%E8%A1%A5%E7%BB%99%E7%AE%B1%402x.png"
      alt=""
    />
    <div class="blind-box-scroll-x">
      <div
        class="blind-box-card-item"
        v-for="(item, index) in BlindBoxList"
        :key="index"
        :style="{background:`#${item.background[0]}`}"
        @click="toBlindBox(item.id)"
      >
       <div class="flex" :style="{background:'#284357'}">
          <div style="display:flex;flex-direction:column">
            <div style="width:19px;height:31px;background:black">
              <img v-if="item.pictures[1]" :src="item.pictures[1]" style="object-fit:cover;width:19px;height:31px;border-top-left-radius:4px;"/>
            </div>
            <div style="width:19px;height:31px;background:black;margin-top:1px;">
              <img v-if="item.pictures[2]" :src="item.pictures[2]" style="object-fit:cover;width:19px;height:31px;"/>
            </div>
          </div>
          <div style="width:73px;height:63px;background:black;margin:0 1px;">
              <img v-if="item.pictures[0]" :src="item.pictures[0]" style="object-fit:cover;width:73px;height:63px;"/>
          </div>
          <div style="display:flex;flex-direction:column">
            <div style="width:19px;height:31px;background:black">
              <img v-if="item.pictures[3]" :src="item.pictures[3]" style="object-fit:cover;width:19px;height:31px;border-top-right-radius:4px;"/>
            </div>
            <div style="width:19px;height:31px;background:black;margin-top:1px;">
              <img v-if="item.pictures[4]" :src="item.pictures[4]" style="object-fit:cover;width:19px;height:31px;"/>
            </div>
          </div>
       </div>
       <div class="blind-box-price">
        <span style="font-size:12px;color:#F43041;font-weight:800">¥{{item.price}}</span>
        <span v-if="item.original_price" style="font-size:10px;color:gray;font-weight:800;text-decoration: line-through;margin-left:4px;">¥{{item.original_price}}</span>
       </div>
      </div>
      <div style="width:30px;height:50px;"> &nbsp;</div>
    </div>
  </div>
 </div>
</template>

<script>
import BlindBoxApi from "@/api/blind-box.api";
import { Component, Vue } from "vue-property-decorator";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {},
})
export default class DailyDiscount extends Vue {
  IndexList = []
  DailyDiscountList = []
  BlindBoxList = []
  async created(){
    this.IndexList = await BlindBoxApi.getIndexList()
    this.DailyDiscountList = this.IndexList.special_sale?.data
    this.BlindBoxList = this.IndexList.blind_box?.data
    console.log(this.DailyDiscountList,'123132')
   
  }
  toDailyDiscount(){
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/daily-discount`);
    }
    this.$router.push({ name: "dailydiscount" });

  }
  toBlindBox(id){
    if(!id){
       if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(`${location.origin}/supply-store`);
        }
     return this.$router.push({name:'supplystore'})
    }else{
      if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/blind-box/${id}`);
    }
     this.$router.push({name:'BlindBoxDetail',params:{
      id
    }})
    }
    
  }
}
</script>

<style lang="scss" scoped>
.daily-discount-component {
  margin-left: 30px;
  border-radius: 6px;
  height: 119px;
  background: linear-gradient(315deg, #f8c9bf 0%, #f8b9cf 100%);
  display: flex;
  align-items: center;
  margin-right: 13px;
  .scroll-x {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    margin-left: 40px;
    flex-wrap: nowrap;
    .daily-discount-card-item {
      width: 110.8px;
      margin: auto 6px;
      position: relative;
      .daily-discount-card-price {
        display: flex;
        .card-price {
          background: linear-gradient(90deg, #fee5d9 0%, #fdd6af 100%);
          border-radius: 2px;
          padding-left: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          .toBuyprice {
            font-size: 12px;
            color: white;
            font-weight: 800;
            display: flex;
            justify-content: center;
            align-items: center;
            text-indent: 2px;
            height: 21px;
            width: 50px;
            background: url('https://vgn-images-new.oss-cn-beijing.aliyuncs.com/static/market/%E7%BC%96%E7%BB%84%202%402x.png');
            background-size: 100% 100%;
          }
        }
      }
      .daily-discount-card-img {
        width: 110.8px;
        height: 75px;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }
   .scroll-x::-webkit-scrollbar {
	  display:none;
  }
}
.daily-discount-side-img {
  width: 57px;
  height: 94px;
  position: absolute;
  left: 10px;
}
.flex{
  display: flex;
  align-items: center;
  padding: 1px;
  border-radius: 4px;
}
.blind-box-component {
  margin-left: 30px;
  border-radius: 6px;
  height: 119px;
  background: linear-gradient(135deg, #B0CADA 0%, #AFD2D9 100%);  
  display: flex;
  align-items: center;
  margin-right: 13px;
  .blind-box-scroll-x {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    margin-left: 40px;
    flex-wrap: nowrap;
    
    .blind-box-card-item {
      margin: auto 6px;
      border-radius: 4px;
      .blind-box-price{
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
     
      
    }
  }
   .blind-box-scroll-x::-webkit-scrollbar {
	  display:none;
  }
}
.blind-box-side-img {
  width: 57px;
  height: 94px;
  position: absolute;
  left: 10px;
}
</style>
