


































import { SignData } from "@/api/bazaar.api";
import { Component, Vue, Emit, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SignPop extends Vue {
    @Prop() signDailyData!:SignData
  async created() {
    document.body.style.overflow = "hidden";
   
  }
  destroy() {
    document.body.style.overflow = "";
  }
  @Emit()
  confirm(){
      return 
  }
}
