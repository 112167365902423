


























import { Component, Prop, Vue } from "vue-property-decorator";
import SalesMiniItem from "@/views/special-sales/components/SalesMiniItem.vue";
import { StoreProduct } from "@/api/cap-store.api";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { SalesMiniItem },
})
export default class SalesMiniList extends Vue {
  @Prop({ default: () => [] })
  readonly items!: StoreProduct[];

  handleJumpList(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/special/sales`);
    }
    this.$router.push({
      name: "SpecialSales",
      query: { time: String(getNow()) },
    });
  }
}
