
















import { Component, Vue } from "vue-property-decorator";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
@Component({})
export default class SalesTitle extends Vue {
    globalState = getModule(GlobalStateStore);

  get appName(){
    if(this.globalState.packageName == 'com.vgn.gamepower'){
      return '游戏动力商城'
    }
    return '超级蒸汽商城'
  }
}
