











































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
import BazzarApi from "@/api/bazaar.api";
@Component({
  components: {},
})
export default class TaskBox extends Vue {
  globalState = getModule(GlobalStateStore);
  @Prop() item!: any;
  @Emit()
  check() {
    return;
  }
  messageData: any = "";
  expStyle: any = "";
  async created() {
    this.messageData = await BazzarApi.messageList();
    this.expStyle = {
      width:
        (this.item.level_info.current_exp / this.item.level_info.next_exp) *
          32 +
        "px",
    };
  }
}
