









import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class FooterItem extends Vue {
  @Prop({ default: "" }) private readonly icon!: string;
  @Prop({ default: false }) private readonly active!: boolean | string | void;
  @Prop({ default: "" }) private readonly title!: string;

  @Emit("click")
  handleClick(): void {}

  get isActive(): boolean {
    return this.active !== false;
  }

  get iconName(): string {
    const suffix = this.isActive ? "_active" : "";
    return `__footer_icon_${this.icon}${suffix}`;
  }
}
