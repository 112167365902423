import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./lib";
import "./app.scss";

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-subscribe"];
Vue.prototype.globalDataB = {
  state:0
}
router.afterEach(() => {
  window.scrollTo(0,0)
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
