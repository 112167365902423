






































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import AlotofItem from "@/views/special-sales/components/AlotofItem.vue";
import SalesTagCards from "@/views/special-sales/components/SalesTagCards.vue";
import SalesTitle from "@/views/special-sales/components/SalesTitle.vue";
import SalesMiniList from "@/views/special-sales/components/SalesMiniList.vue";
import Search from "@/views/special-sales/components/Search.vue";
import SalesMarket from "@/views/special-sales/components/SalesMarket.vue";
import NewStore from "@/views/special-sales/components/NewStore.vue";
import SubjectTabs from "@/views/special-sales/components/SubjectTabs.vue";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
import CapStoreApi, {
  StoreProduct,
  StoreProductCategory,
} from "@/api/cap-store.api";
import { Tab, Tabs, List, Cell } from "vant";
import ProductList from "@/views/special-sales/components/ProductList.vue";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";
import { path } from "animejs";
import MemberApi from "@/api/member.api";
@Component({
  components: {
    ProductList,
    SalesMiniList,
    SalesTitle,
    SalesTagCards,
    Search,
    AlotofItem,
    SalesMarket,
    SubjectTabs,
    Tab,
    Tabs,
    List,
    Cell,
    NewStore,
  },
})
export default class Store extends Vue {
  specialSale: StoreProduct[] = [];
  category: StoreProductCategory[] = [];
  @Ref("line") lineRef!: HTMLDivElement;
  @Ref("firstTab") firstTabRef!: HTMLDivElement;
  state = true;
  activestate = false;
  popstate = false;
  get isSpecialSale(): boolean {
    return Boolean(this.specialSale.length);
  }
 
  get statusBar() {
    return this.globalState.statusBarHeight;
  }
  closeAlotof() {
    this.popstate = false;
  }
  tosell() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sell`);
    }
    this.$router.push({ name: "sell" });
  }
  todemand() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/demand`);
    }
    this.$router.push({ name: "demand" });
  }
  morePop() {
    this.popstate = true;
    document.documentElement.style.overflowY = "hidden";
  }
  get isNotApp() {
    return InteractionLib.isApp();
  }
  toorder() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-order`);
    }
    this.$router.push({ path: "/my-order" });
  }
  globalState = getModule(GlobalStateStore);

  StoreDeatil: any = null;
  async created(): Promise<void> {
    this.globalState = getModule(GlobalStateStore);
    if(this.$route.query.state){
      this.state = false
    }
    this.init()
  }
  isVipMember = false
  async init(){
     const [specialSale, storeDeatil] = await Promise.all([
      CapStoreApi.getSpecialSale(1, 3),
      CapStoreApi.getStore(),
    ]);
    this.StoreDeatil = storeDeatil;
    this.specialSale = specialSale;
    this.category = this.StoreDeatil.subject_tabs;
    if (this.globalState.sce != 0) {
      this.state = false;
    }
   const {status} =  await MemberApi.checkMemberIs()
   if(status == 1){
     this.isVipMember = true
   }
  }
  mounted() {
    observer("refreshInit", () => this.init());
  }
}
