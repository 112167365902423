



















import { Component, Prop, Vue } from "vue-property-decorator";
import InteractionLib from "@/utils/interaction.lib";

@Component({})
export default class SalesTagCards extends Vue {
  @Prop({ default: "0px" }) readonly top!: string;

  tags = [
    { icon: "__vendor_icon", label: "厂商团购" },
    { icon: "__money_icon", label: "平台补贴" },
    { icon: "__just_icon", label: "绝对正品" },
    { icon: "__chart_icon", label: "全网低价" },
  ];

  handleOpenBlindBox(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/welfare`);
    }
    this.$router.replace({ name: "Welfare" });
  }
}
