













import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { Tabs, Tab } from "vant";
import ProductList from "@/views/special-sales/components/ProductList.vue";
import { MallHomeSubjectTab } from "@/api/cap-store.api";
import InteractionLib from "@/utils/interaction.lib";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
@Component({
  components: {
    ProductList,
    Tabs,
    Tab,
  },
})
export default class SubjectTabs extends Vue {
  widthheight = {};
  contentWidth = {};
  wideHigh = 0;
  active = 0;
  @Prop() items!: MallHomeSubjectTab;
  @Prop() isVipMember!: boolean;
  globalState = getModule(GlobalStateStore);
  get statusBarState() {
    if(this.globalState.statusBarHeight)return true
    return false
  }
  get isNotApp(){
    if(InteractionLib.isApp()){
      return `${ this.globalState.statusBarHeight + 60}px`

    }
    return '0'
  }
  async created() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 16;
    this.contentWidth = {
      width: this.wideHigh + "px",
      height: this.wideHigh + "px",
    };
    this.widthheight = {
      width: this.wideHigh + "px",
    };
  }
}
