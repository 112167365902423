import axios from "axios";
import { Toast } from "vant";
import InteractionLib from "@/utils/interaction.lib";

export class WechatApi {
  static async getAuthSign(appid = "wxebb0f826b7b56ce7"): Promise<AuthSign> {
    return axios.post(`wechat/authorization/sign/${appid}`, {
      uri: location.href,
    });
  }

  static async getAuthUri(appid = "wxebb0f826b7b56ce7"): Promise<string> {
    if (InteractionLib.isApp()) {
      InteractionLib.login();
      throw new Error("禁止微信登录");
    }
    const { pathname, search, origin } = location;
    const uri = window
      .btoa(pathname + search)
      .split("/")
      .join("--");
    const toast = Toast.loading({ message: "授权中..." });
    const url = await axios.post<string, string>(
      `/wechat/authorization/uri/${appid}`,
      {
        uri: `${origin}/authorization/${uri}`,
      }
    );
    toast.close();
    return url;
  }
}

export interface AuthSign {
  signature: string;
  appId: string;
  nonceStr: string;
  timestamp: number;
}
