export const TYPE_TEXT = [
  "优秀",
  "精良",
  "史诗",
  "传奇",
  "神话",
  "巅峰一",
  "巅峰二",
  "巅峰三",
];
export const PACKAGE_NAME = {
  "com.vgn.gamepower": "游戏动力",
  "com.eshop.zzzb": "主掌中宝",
  "com.vgn.steampro": "超级蒸汽",
  "com.vgn.flutter_steampro": "超级蒸汽极速版",
  "com.vgn.fluttersteam": "超级蒸汽",
};
export const SHARE_TEXT = "Steam超值好价游戏在这买！史低、底价入手超划算！";
export const TOKEN_NAME = "token-1640261874";
export const PHONE_NAME = "phone-1640261874";
export const OPENID_NAME = "openid-1640261874";
export const LOGOUT_NAME = "logout-time";
export const DOWNLOAD_APP = {
  android: <Record<string, string>>{
    bilibili: "https://cdn.vgn.cn/app/app_v2.3.0_81_channel_bilibili.apk",
    gamepower: "https://cdn.vgn.cn/app/app_v2.3.0_81_channel_gamepower.apk",
    douyin: "https://cdn.vgn.cn/app/app_v2.3.0_81_channel_douyin.apk",
  },
  ios: "https://apps.apple.com/app/id1571680401",
};
