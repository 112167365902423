import NProgress from "nprogress";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import DefaultLayout from "@/layout/Default.vue";
import { LayoutRouter } from "@/router/layout.router";
import { BaseRouter } from "@/router/base.router";
import { AuthRouter } from "@/router/auth.router";
import InteractionLib from "@/utils/interaction.lib";
import { ActivityRouter } from "@/router/activity.router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "Layout",
    component: DefaultLayout,
    children: LayoutRouter,
  },
  ...BaseRouter,
  ...AuthRouter,
  ...ActivityRouter,
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  document.documentElement.scrollTo(0,0)
  document.title = to.meta?.title || "";
  document
    .querySelector("html")
    ?.classList.remove("__theme_dark", "__theme_white");
  document
    .querySelector("html")
    ?.classList.add(to?.meta?.class || "__theme_white");
  NProgress.start();
  if (await InteractionLib.flutterInAppWebViewPlatformReady()) {
    InteractionLib.getUserInfo();
  }
  next();
});
router.afterEach(() => NProgress.done());

export default router;
