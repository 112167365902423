











































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import TaskPop from "./TaskBox.vue";
import SalesActive from "./SalesActive.vue";
import BazzarApi from "@/api/bazaar.api";
import { countdown, getNow, observer } from "@/utils/common.util";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
import moment from "moment";
import InteractionLib from "@/utils/interaction.lib";
import { WechatApi } from "@/api/wechat.api";

@Component({
  components: { TaskPop, SalesActive },
})
export default class WelfareHead extends Vue {
  messageListData: any = {};
  countTime: any;
  globalState = getModule(GlobalStateStore);
  times: any;
  countTimer: any = "";
  list: any;
  totalRouter: any;
  loginFlag = false;
  wechat = InteractionLib.mobile.wechat;

  get audit_version() {
    return this.globalState.audit_version;
  }

  @Emit()
  toOrder() {
    return;
  }

  get statusBar() {
    return this.globalState.statusBarHeight;
  }

  get login() {
    return !!this.globalState.userToken;
  }

  get userInfo() {
    return this.globalState.userInfo;
  }

  get showTaskFlag() {
    return InteractionLib.isApp();
  }

  get reward_days() {
    const { userInfo } = this.globalState;
    return userInfo?.reward_days || 0;
  }

  get reward_days_time() {
    const { userInfo } = this.globalState;
    return userInfo?.reward_days_time || 0;
  }

  mounted() {
    this.times = setInterval(() => {
      if (!this.reward_days_time) return;
      const tt = moment(
        moment().add(1, "days").format("YYYY-MM-DD 00:00:00")
      ).format("X");
      const { day, hour, minute, second } = countdown(Number(tt));
      this.countTimer = `${hour}时${minute}分${second}秒`;
    }, 1000);
  }

  destroy() {
    clearInterval(this.times);
  }

  async created() {
    this.messageListData = await BazzarApi.messageList();
    this.totalRouter = await BazzarApi.getTaskMessage();
    await this.init();
  }

  async init() {
    if (!this.userInfo) {
      console.log("daskjfojadgfodajfpjdapfjpasjfpoajspofjpaso", this.login);
      console.log(
        !this.userInfo,
        "this.userInfothis.userInfothis.userInfothis.userInfothis.userInfo"
      );
      await this.globalState.getUserInfo();
    }
  }

  async toLogin() {
    if (!this.wechat) {
      InteractionLib.login();
      return;
    }
    location.href = await WechatApi.getAuthUri();
  }

  toLottery() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(this.totalRouter.mine.lotteryURL);
    }
    location.href = this.totalRouter.mine.lotteryURL;
  }

  toTask() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(this.totalRouter.mine.taskURL);
    }
    location.href = this.totalRouter.mine.taskURL;
  }
}
