










































import { Component, Prop, Vue } from "vue-property-decorator";
import { Swipe, SwipeItem } from "vant";
import BazzarApi from "@/api/bazaar.api";
import { countdown } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
@Component({
  components: { Swipe, SwipeItem },
})
export default class SalesActive extends Vue {
  @Prop() showTaskFlag:any
  currentIndex = 0;
  timer: any;
  changeImg(index: any) {
    this.currentIndex = index;
  }
  get showIndicators(){
    if(this.bannerData.length > 1)return true
    return false
  }
  bannerData: any = "";
  toBanner(item: any) {
    let result = JSON.parse(item.behavior_data);
    if (result.code == 4 || result.code == 8) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(result.data);
      }
      location.href = result.data;
    }
    if (result.code == 401) {
      if (InteractionLib.isApp()) {
        return InteractionLib.router(
      20,
      JSON.stringify({
        url: result.data,
      })
    );
      }
      location.href = result.data;
    }
  }
  timeList: any[] = [
    `
      <div class="sales-countdown-tip">距结束<span style="color:red">0</span>天</div>
      <div class="sales-countdown-text">
        <span class="sales-redCard">00</span>:<span class="sales-redCard">00</span>:<span class="sales-redCard">00</span>
      </div>
  `,
  ];
  async created() {
    
    if(this.showTaskFlag){
      this.bannerData = await BazzarApi.getBannerAppPic();
    }else{
      this.bannerData = await BazzarApi.getBannerPic();
    }
    
    if (this.bannerData.length) {
      const { day, hour, minute, second } = countdown(
        this.bannerData[this.currentIndex].end_time
      );
      this.bannerData.forEach((item: any, index: number) => {
        this.timer = setInterval(() => {
          if(moment().unix()<item.start_time){
            const { day:days, hour:hours, minute:minutes, second:seconds } = countdown(item.start_time);
            this.timeList[index] = `
                <div class="sales-countdown-tip">距开始<span style="color:red">${days}</span>天</div>
                <div class="sales-countdown-text">
                  <span class="sales-redCard">${hours}</span>:<span class="sales-redCard">${minutes}</span>:<span class="sales-redCard">${seconds}</span>
                </div>
              `;
                this.$forceUpdate();

          }else{
               if (!item.end_time) return;
                const { day, hour, minute, second } = countdown(item.end_time);
                this.timeList[index] = `
                <div class="sales-countdown-tip">距结束<span style="color:red">${day}</span>天</div>
                <div class="sales-countdown-text">
                  <span class="sales-redCard">${hour}</span>:<span class="sales-redCard">${minute}</span>:<span class="sales-redCard">${second}</span>
                </div>
              `;
                this.$forceUpdate();
          }
          
       
        }, 1000);
      });
    }
  }
}
