import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import axios from "axios";
import InteractionLib from "@/utils/interaction.lib";
import { isMobile } from "@/utils/common.util";

const globalState = getModule(GlobalStateStore);

function isHttpStr(str = ""): boolean {
  return str.startsWith("http");
}

axios.interceptors.request.use((config) => {
  const { userToken, packageName, versionCode, headerData } = globalState;
  const isStartHttp = isHttpStr(config.url);
  const track_id = localStorage.getItem("track_id");
  const channel = localStorage.getItem("channel");
  const wechatPackageName = InteractionLib.mobile.wechat
    ? "com.wechat.mall.ui"
    : packageName;
  const is_mobile = isMobile(navigator.userAgent);
  const header: Record<string, string> = {
    packagename: wechatPackageName,
    soft_version: "1.0.0",
    version_name: versionCode,
    ...(headerData || {}),
    ...(track_id ? { track_id } : {}),
    ...(track_id ? { "content-track-id": track_id } : {}),
    ...(channel ? { channel } : {}),
    web_device_type: is_mobile.ios
      ? "ios"
      : is_mobile.android
      ? "android"
      : is_mobile.wechat
      ? "wechat"
      : (headerData as any)?.device_type,
  };
  config.baseURL = isStartHttp ? "" : process.env.SITE_CONFIG.BASE_URL;
  if (userToken) header["content-token"] = userToken;
  config.headers = {
    // ...headerData,
    ...(config.headers || {}),
    ...header,
  };
  return config;
});

axios.interceptors.response.use((response) => {
  const {
    code = 0,
    msg = "",
    message = "请求错误",
    data = undefined,
  } = response?.data || {};

  if (code === 401) {
    globalState.setUserToken(null);
    globalState.setLoginTip(true);
  }
  if (code !== 200) {
    throw new InterceptorsError(code, msg || message, data);
  }
  return data;
});

export class InterceptorsError extends Error {
  constructor(
    public code = 500,
    public message = "请求有误",
    public data: any = null
  ) {
    super();
  }
}
