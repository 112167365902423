import { RouteConfig } from "vue-router";

export const ActivityRouter: Array<RouteConfig> = [
  {
    path: "/activity/box-result/:private_id",
    name: "BlindBoxActivityPrivateIdResult",
    component: () => import("@/views/activity/BlindBoxResult.vue"),
    meta: {
      title: "未成年人须在家人的监督下进行购买",
      desc: "未成年人须在家人的监督下进行购买",
      class: "__theme_white",
    },
  },
  {
    path: "/activity/boxv3/:private_id",
    name: "BlindBoxActivityPrivateId",
    component: () => import("@/views/activity/BlindBoxV3.vue"),
    meta: {
      title: "未成年人须在家人的监督下进行购买",
      desc: "未成年人须在家人的监督下进行购买",
      class: "__theme_white",
    },
  },
  {
    path: "/activity/box/:private_id",
    name: "BlindBoxActivityPrivateId",
    component: () => import("@/views/activity/BlindBoxV2.vue"),
    meta: {
      title: "未成年人须在家人的监督下进行购买",
      desc: "未成年人须在家人的监督下进行购买",
      class: "__theme_white",
    },
  },
  {
    path: "/activity/box/:private_id/:phone",
    name: "BlindBoxActivityPrivateIdDetail",
    component: () => import("@/views/activity/BlindBoxDetail.vue"),
    meta: {
      title: "未成年人须在家人的监督下进行购买",
      desc: "未成年人须在家人的监督下进行购买",
      class: "__theme_white",
    },
  },
  {
    path: "/activity/dragon-boat-festival",
    name: "DragonBoatFestival",
    component: () => import("@/views/activity/DragonBoatFestival.vue"),
    meta: {
      title: "端午甜咸大作战",
      desc: "端午甜咸大作战",
    },
  },
  {
    path: "/activity/steam-knowledge-contest",
    name: "SteamKnowledgeContest",
    component: () => import("@/views/activity/SteamKnowledgeContest.vue"),
    meta: {
      title: "Steam游戏知识竞答",
      desc: "Steam游戏知识竞答",
    },
  },
];
