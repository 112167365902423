



















import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import WelfareBarrageItem from "@/views/welfare/components/WelfareBarrageItem.vue";
import { Swipe, SwipeCell, SwipeItem } from "vant";
import { ISupplyShufflingMessage } from "@/api/supply-package.api";

@Component({
  components: { WelfareBarrageItem, Swipe, SwipeCell, SwipeItem },
})
export default class WelfareBarrage extends Vue {
  @Ref("barrage-box") barrageRef!: HTMLDivElement;

  @Prop({ default: () => [] })
  private readonly items!: ISupplyShufflingMessage[];

  index = 0;

  handleChange(index: number): void {
    setTimeout(() => (this.index = index), 700);
  }

  mounted(): void {}
}
